import React, { useContext, useEffect, useState } from "react";

import moment from "moment";

import { listPatientAppointments } from "~/src/api-client/patients";
import { useContextValue } from "~/src/context";
import namespaced from "~/src/utils/debug";

const debug = namespaced("src/patient/DisplayAppointments");

const DisplayAppointments = ({ patientId }: { patientId: string }) => {
  const [appointments, setAppointments] = useState<any>([]);
  const { session, rehydrated } = useContextValue();

  const fetchAppointments = async () => {
    if (session) {
      const fetchedAppointments = await listPatientAppointments(session.accessToken, patientId);
      if (fetchedAppointments) {
        setAppointments(fetchedAppointments);
      }
    }
  };

  useEffect(() => {
    fetchAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated]);

  return (
    <div>
      {appointments?.map((app: any, i: number) => (
        <div key={i} className="w-full flex justify-between odd:bg-gray-100 py-[5px] px-[10px] text-sm items-center">
          <div>{`${moment(app.begin_date).format("L")}`}</div>
          <div className="text-right">{app.service_names}</div>
        </div>
      ))}
    </div>
  );
};

export default DisplayAppointments;
