import React, { useEffect, useState } from "react";

import { Link } from "gatsby";
import moment from "moment";

import { getPatientPrescriptions } from "~/src/api-client/patients";
import { useContextValue } from "~/src/context";
import { Prescription } from "~/src/types/models";
import { PrescriptionLink } from "~/src/types/prescription";
import namespaced from "~/src/utils/debug";

const debug = namespaced("src/components/patients/DisplayOrdenMedica");

const DisplayPrescriptions = ({ patientId }: { patientId: string }) => {
  const [prescriptions, setPrescriptions] = useState<PrescriptionLink[] | undefined>([]);
  const { session, rehydrated } = useContextValue();

  const fetchPrescriptions = async () => {
    if (session) {
      const fetchedPrescriptions = await getPatientPrescriptions(session?.accessToken, patientId);
      if (fetchedPrescriptions) {
        setPrescriptions(fetchedPrescriptions.data);
      }
    }
  };

  useEffect(() => {
    fetchPrescriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated]);

  return (
    <div>
      {prescriptions?.map((prescription: PrescriptionLink, i: number) => (
        <div key={i} className="even:bg-gray-100 p-[10px]">
          <div className="text-xs md:text-sm">{moment(prescription.created_at).format("L")}</div>
          <div className="text-xs md:text-md text-examedi-blue-strong">
            <Link to={prescription.file_link} target="_blank">
              {prescription.filename}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DisplayPrescriptions;
