import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Link } from "gatsby";

import { getPatient } from "~/src/api-client/patients";
import DashboardLayout from "~/src/components/DashboardLayout";
import DisplayAppointments from "~/src/components/Patient/DisplayAppointments";
import DisplayPrescriptions from "~/src/components/Patient/DisplayPrescriptions";
import { useContextUpdate, useContextValue } from "~/src/context";
import { User } from "~/src/types/models";

const PatientProfile = ({ id }: { id: string }) => {
  const patientId: string = id;
  const [patient, setPatient] = useState<User | null>(null);
  const { session, rehydrated } = useContextValue();
  const { setPreLoadingStatus } = useContextUpdate();

  const fetchPatient = async (queryPatientId: string) => {
    if (session) {
      setPreLoadingStatus(true);
      const queriedPatient = await getPatient(session.accessToken, queryPatientId);
      if (queriedPatient) {
        setPatient(queriedPatient);
      }
      setPreLoadingStatus(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      fetchPatient(patientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated]);

  return (
    <DashboardLayout
      header={
        <div
          className={clsx(
            "bg-white",
            "w-full p-[20px] md:p-[40px]",
            "min-h-[127px] md:min-h-[167px]",
            "border-b-[1px] border-gray-200",
            "flex items-center justify-between",
          )}
        >
          <div>
            <div className="text-xs md:text-md text-gray-500">Perfil de Paciente</div>
            <div className="text-lg md:text-3xl mt-[5px] font-bold">{patient?.fullname}</div>
            <div className="text-xs md:text-md text-gray-400 mt-[5px]">Gestionar paciente</div>
          </div>
          <div className="flex md:block flex-col">
            <Link to={`/app/prescriptions?patient_id=${patientId}`}>
              <button className="rounded-full bg-blue-700 p-[10px] md:p-[20px] text-xs md:text-sm text-white hover:bg-blue-500 hover:cursor-pointer">
                Enviar Orden Médica
              </button>
            </Link>
            <button className="mt-[10px] md:mt-[0px] md:ml-[20px] rounded-full bg-blue-700 p-[10px] md:p-[20px] text-xs md:text-sm text-white hover:bg-blue-500 hover:cursor-pointer">
              Agendar Exámenes
            </button>
          </div>
        </div>
      }
    >
      <div className="flex flex-col-reverse xl:flex-row justify-between">
        <div className="mt-6 md:mt-0 w-full min-h-screen col-span-1 bg-white rounded-lg drop-shadow-md">
          <div className="bg-gray-200 py-[10px] px-[40px] text-sm text-gray-600 font-bold">Agendas</div>
          <div className="p-[40px]">
            <DisplayAppointments patientId={patientId} />
          </div>
        </div>
        <div className="w-full min-h-screen ml-0 md:ml-6">
          <div className="w-full min-h-[100px] bg-white rounded-lg drop-shadow-md">
            <div className="bg-gray-200 px-[20px] py-[10px] md:px-[40px] text-sm text-gray-600 font-bold">
              Información del Paciente
            </div>
            <div className="grid grid-cols-2 p-[20px] md:px-[40px] md:py-[20px] text-sm gap-4">
              <div className="col-span-1">
                <div className="font-semibold">Nombre y Apellido</div>
                <div>{patient?.fullname}</div>
              </div>
              <div className="col-span-1">
                <div className="font-semibold">Correo</div>
                <div>{patient?.email}</div>
              </div>
              <div className="col-span-1">
                <div className="font-semibold">Nro. Cel</div>
                <div>{patient?.phone}</div>
              </div>
              <div className="col-span-1">
                <div className="font-semibold">Sexo</div>
                <div>{patient?.gender === "male" ? "Masculino" : "Femenino"}</div>
              </div>
              <div className="col-span-1">
                <div className="font-semibold">Nro. Documento</div>
                <div>{patient?.document_number}</div>
              </div>
            </div>
          </div>
          <div className="mt-[20px] w-full min-h-[150px] bg-white rounded-lg drop-shadow-md">
            <div className="bg-gray-200 p-[20px] md:py-[10px] md:px-[40px] text-sm text-gray-600 font-bold">
              Órdenes Médicas
            </div>
            <div className="p-[20px] md:px-[40px] md:py-[20px]">
              <DisplayPrescriptions patientId={patientId} />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PatientProfile;
